<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.sensors-all') }}
      </h2>
      <p>
        <button
          class="button is-primary"
          @click.prevent="create"
        >
          <octicon :icon="plus" /> <span>{{ $t('create') }}</span>
        </button>
      </p>
    </div>

    <box>
      <data-table
        ref="table"
        :items="items"
        :theme="$_ui_theme_tables"
      >
        <data-column
          field="id"
          label="ID"
        />
        <data-column
          field="name"
          :label="$t('name')"
        />
        <data-column
          field="key"
          :label="$t('token')"
        />
        <data-column
          field="version"
          :label="$t('versionSensor')"
        />
          <!-- <template slot-scope="{ value }">
            <span>{{ value | dmyhs }}</span>
          </template>
        </data-column> -->
        <data-column
          :label="$t('actions')"
          :sortable="false"
        >
          <template slot-scope="props">
            <button
              v-show="can(`update:sensor:${props.item.id}`)"
              class="button is-small is-warning is-light mr-2"
              @click.prevent="edit(props.item)"
            >
              <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
            </button>

            <button
              v-show="can(`delete:sensor:${props.item.id}`)"
              class="button is-small is-danger is-light"
              @click.prevent="destroy(props.item)"
            >
              <octicon :icon="trashcan" /> <span>{{ $t('delete') }}</span>
            </button>
          </template>
        </data-column>
      </data-table>
    </box>

    <box>
      <data-table
        :items="clients"
        :theme="$_ui_theme_tables"
        sort-desc
        sort-by="count"
      >
        <data-column
          field="host"
          :label="$t('clients')"
        />
        <data-column
          field="sensor_name"
          :label="$t('sensorName')"
          :searchable="false"
          :sortable="false"
        />
        <data-column
          field="count"
          :label="$t('connection')"
          :searchable="false"
        />
      </data-table>
    </box>

    <modal v-if="item" :show.sync="modal" content-width="800px">
      <form @submit.prevent="submit">
        <box>
          <div slot="header">
            <span v-if="item.id">{{ $t('editSensor') }}</span><span v-else>{{ $t('newSensor') }}</span> <span v-if="item.name">-</span> {{ item.name }}
          </div>

          <div class="field">
            <label class="label">{{ $t('name') }}</label>
            <div class="control">
              <input
                v-model="item.name" class="input" type="text"
                :placeholder="$t('sensorName')"
                required
              >
            </div>
          </div>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </box>
      </form>
    </modal>
  </section>
</template>

<script>
import { DataTable, DataColumn } from 'vue-teible'
import { pencil, plus, trashcan } from 'octicons-vue'
import { Modal } from '@cyradar/ui'

export default {
  components: { DataTable, DataColumn, Modal },
  data () {
    return {
      modal: false,
      item: null,
      step: 1
    }
  },
  computed: {
    plus () {
      return plus
    },
    trashcan () {
      return trashcan
    },
    pencil () {
      return pencil
    },
    language () {
      return this.$store.state.ui.language
    }
  },
  watch: {
    language (v, o) {
      if (!o) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.$nextTick(() => {
        this.$refs.table.loadSlots()
      })
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    resetItem () {
      this.step = 1
      this.item = {
        name: ''
      }
    },
    create () {
      this.resetItem()
      this.modal = true
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    store () {
      return this.$http.post('/api/v1/sensors', this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Sensor Created'
          })

          this.modal = false
          this.resetItem()
        })
    },
    update () {
      return this.$http.patch(`/api/v1/sensors/${this.item.id}`, this.item)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Sensor Updated'
          })

          this.modal = false
          this.resetItem()
        })
    },
    destroy (x) {
      return this.$http.delete(`/api/v1/sensors/${x.id}`)
        .then(body => {
          if (!body) {
            return
          }

          this.$refs.table.reloadItems()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: 'Sensor Deleted'
          })
        })
    },
    items (filter, sort, pagination) {
      return this.$http.get(`/api/v1/sensors?page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }
          console.log(body.data)
          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }
          console.log(data.data)
          return data.data
        })
    },
    clients (filter, sort, pagination) {
      return this.$http.get(`/api/v1/netflow/hosts?page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }

          return data.data
        })
    }
  }
}
</script>
