<template>
  <box>
    <data-table
      ref="table"
      :items="prefixTableItems"
      :theme="$_ui_theme_tables"
      sort-desc
      sort-by=""
    >
      <data-column
        field="subnet"
        :label="$t('subnet')"
      />
      <data-column
        field="hosts"
        :label="$t('hosts')"
        :searchable="false"
      />
      <data-column
        field="connections"
        :label="$t('connection')"
        :searchable="false"
      />
    </data-table>
  </box>
</template>

<script>
import { DataTable, DataColumn } from 'vue-teible'
export default {
  name: 'SensorPrefixTable',
  components: { DataTable, DataColumn },
  props: {
    sensorId: {
      type: String,
      default: ''
    },
    from: {
      type: Date,
      default: null
    },
    to: {
      type: Date,
      default: null
    }
  },
  computed: {
    timeQuery () {
      let query = ''
      if (this.from) {
        query += `from=${this.from.toISOString()}&`
      }
      if (this.to) {
        query += `to=${this.to.toISOString()}&`
      }
      return query
    }
  },
  watch: {
    timeQuery: {
      immediate: true,
      handler () {
        if (!this.$refs.table) {
          return
        }
        this.$refs.table.reloadItems()
      }
    }
  },
  methods: {
    prefixTableItems (filter, sort, pagination) {
      return this.$http.get(`/api/v1/netflow/prefixes/${this.sensorId}?${this.timeQuery}page=${pagination.page}&limit=${pagination.perPage}&query=${filter.query}&sort=${sort.by}&order=${sort.order}`)
        .then(body => {
          if (!body) {
            return
          }
          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }
          return data.data
        })
    }
  }
}
</script>

<style scoped>

</style>
