<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.sensors-overview') }}
      </h2>
      <range-picker
        ref="rangepicker"
        class="level-right"
        :range.sync="dates"
        :lookup.sync="dateRange"
        :disabled="loading"
        min-hour
      />
    </div>
    <tabs :theme="$_ui_theme_tabs">
      <tab
        v-for="sensor in sensors" :key="sensor.id" :title="sensor.name"
        :hash="sensor.id" class="p-0 pt-4"
      >
        <box>
          <div class="is-flex">
            <div style="position: relative; flex: none; width: 33%">
              <chart
                type="gauge"
                :series="sensor.resourceLatest"
                :loading="loading"
                lazy
                style="height: 300px"
              />
              <div class="is-flex is-justify-content-center" style="position: absolute; width: 100%; top: 0; left: 0; text-align: center;">
                <div v-for="(bandwidth, key) in sensor.bandwidth" :key="key" class="py-2 px-4">
                  <p class="is-uppercase is-size-7">
                    {{ key }}
                  </p>
                  {{ bandwidthHuman(bandwidth.received_bytes_avg * 8) }}
                </div>
              </div>
            </div>
            <div style="flex: 1; overflow: hidden;">
              <chart
                type="timeseries"
                :series="sensor.resourceSeries"
                :options="statsOptionsMonitor"
                :loading="loading"
                lazy
                style="height: 300px"
              />
            </div>
          </div>
        </box>

        <box>
          <p slot="header">
            {{ $t('sensors.histogram-connection') }}
          </p>
          <div class="columns">
            <div class="column is-one-third">
              <chart
                v-if="statsNetflowsTop"
                type="pie"
                :series="statsNetflowsTop[sensor.id]"
                :options="statsOptionsNetflowsTop"
                :loading="loading"
                lazy
                style="height: 250px"
              />
            </div>
            <div class="column">
              <chart
                v-if="statsNetflows"
                type="timeseries"
                :series="statsNetflows[sensor.id]"
                :options="statsOptionsAllWithRange"
                :loading="loading"
                lazy
                style="height: 250px"
              />
            </div>
          </div>
        </box>
        <sensor-prefix-table :sensor-id="sensor.id" :from="dates[0]" :to="dates[1]" />
      </tab>
    </tabs>
  </section>
</template>

<script>
import RangePicker from '@/components/RangePicker'
import Chart from '@/components/Chart'
import { statsSeriesTimeseries, sensorSeriesGauge, statsSeriesPie, bandwidthHuman } from '@/utils'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import { Tabs, Tab } from '@cyradar/ui'
import { scaleSqrt } from 'd3'
import SensorPrefixTable from '@/views/sensors/SensorPrefixTable'

export default {
  components: { SensorPrefixTable, RangePicker, Chart, Tabs, Tab },
  data () {
    return {
      dateRange: 'hour-24',
      dates: [],
      loading: false,
      sensors: null,
      wsSensorsMonitor: null,
      wsSensorsMonitorRetry: null,
      wsSensorsBandwidth: null,
      wsSensorsBandwidthRetry: null,
      statsNetflows: null,
      statsNetflowsTop: null,
      statsPrefixes: null,
      prefixTableLoading: false
    }
  },
  computed: {
    wst () {
      return this.$store.getters.wst
    },
    rangeFormat () {
      return '%d/%m/%Y %H:%M'
    },
    categoriesSensorResource () {
      return ['cpu', 'disk', 'ram']
    },
    sensorIDs () {
      if (!this.sensors) {
        return []
      }

      return Object.keys(this.sensors)
    },
    statsOptionsAllWithRange () {
      return {
        axis: {
          x: {
            tick: {
              format: this.rangeFormat
            }
          }
        }
      }
    },
    statsOptionsMonitor () {
      const self = this
      return {
        axis: {
          x: {
            tick: {
              format: this.rangeFormat
            }
          },
          y: {
            label: 'Usage (%)'
          },
          y2: {
            label: 'Bandwidth',
            show: true
          }
        },
        tooltip: {
          format: {
            value (value, ratio, id) {
              if (self.categoriesSensorResource.indexOf(id) === -1) {
                return bandwidthHuman(value * 8)
              }
              return Number(value).toFixed(2) + '%'
            }
          }
        }
      }
    },
    statsOptionsNetflowsTop () {
      return { tooltip: { format: { value: function (value, ratio, id) { return value } } } }
    },
    statsOptionsPrefixes () {
      const self = this
      return {
        axis: {
          y: {
            tick: {
              format (d) {
                return Math.round(self.scale.invert(d))
              }
            }
          }
        }
      }
    },
    scale () {
      return scaleSqrt()
        .domain([0, 256, 100000])
        .range([0, 20, 100])
    }
  },
  watch: {
    dates (v) {
      if (!v || !v.length) {
        return
      }

      this.loadSensorMonitoring().then(this.loadSensorMonitoringLatest)
    },
    sensorIDs (v) {
      if (!v) {
        return
      }

      this.loadSensorNetflows()
      this.loadSensorNetflowsTop()
    },
    wsSensorsMonitor (v, o) {
      if (o) {
        o.close()
      }

      v.onopen = e => {
        this.wsSensorsMonitorRetry = null
      }

      v.onclose = e => {
        if (this._isBeingDestroyed) {
          return
        }

        if (!this.wsSensorsMonitorRetry) {
          this.wsSensorsMonitorRetry = new Date()
        }

        setTimeout(() => {
          const diff = differenceInSeconds(new Date(), this.wsSensorsMonitorRetry)
          if (diff >= 60) {
            return
          }

          console.log('ws', 'reconnect', v.url, this.wsSensorsMonitorRetry)
          this.wsSensorsMonitor = new WebSocket(v.url)
        }, 5000)
      }

      v.onmessage = e => {
        const data = JSON.parse(e.data)
        if (!data.data) {
          return
        }

        data.data.cpu = data.data.cpu_usage
        data.data.ram = data.data.ram_usage
        data.data.disk = data.data.disk_usage

        if (!this.sensors || !this.sensors[data.data.sensor_id]) {
          return
        }

        this.sensors[data.data.sensor_id].resourceLatest = sensorSeriesGauge(data.data, this.categoriesSensorResource)
      }
    },
    wsSensorsBandwidth (v, o) {
      if (o) {
        o.close()
      }

      v.onopen = e => {
        this.wsSensorsBandwidthRetry = null
      }

      v.onclose = e => {
        if (this._isBeingDestroyed) {
          return
        }

        if (!this.wsSensorsBandwidthRetry) {
          this.wsSensorsBandwidthRetry = new Date()
        }

        setTimeout(() => {
          const diff = differenceInSeconds(new Date(), this.wsSensorsBandwidthRetry)
          if (diff >= 60) {
            return
          }

          console.log('ws', 'reconnect', v.url, this.wsSensorsBandwidthRetry)
          this.wsSensorsBandwidth = new WebSocket(v.url)
        }, 5000)
      }

      v.onmessage = e => {
        const data = JSON.parse(e.data)
        if (!data.data) {
          return
        }

        if (!this.sensors[data.data.sensor_id]) {
          return
        }

        if (!this.sensors[data.data.sensor_id].bandwidth) {
          this.sensors[data.data.sensor_id].bandwidth = {}
        }

        const bandwidth = this.sensors[data.data.sensor_id].bandwidth
        const i = data.data.interface

        bandwidth[i] = {
          received_bytes_avg: data.data.received_bytes / data.data.interval
        }
      }
    }
  },
  beforeDestroy () {
    if (this.wsSensorsBandwidth) {
      this.wsSensorsBandwidth.close()
    }

    if (this.wsSensorsMonitor) {
      this.wsSensorsMonitor.close()
    }
  },
  methods: {
    bandwidthHuman,
    pullSensorMonitoringLatest (ids) {
      return Promise.all([
        ...ids.map(id => {
          return this.$http.get(`/api/v1/sensor-monitoring/${id}/last`)
            .then(body => body?.data)
            .then(data => {
              if (!data) {
                return
              }

              this.sensors[id].resourceLatest = sensorSeriesGauge(data.data || {}, this.categoriesSensorResource)
            })
        }),
        ...ids.map(id => {
          return this.$http.get(`/api/v1/sensor-monitoring/bandwidth/${id}/last`)
            .then(body => body?.data)
            .then(data => {
              if (!data) {
                return
              }

              this.sensors[id].bandwidth = data.data
            })
        })
      ])
    },
    loadSensorMonitoringLatest () {
      if (!this.wst || !this.sensorIDs) {
        return
      }

      this.wsSensorsMonitor = new WebSocket(`${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${window.location.host}/ws/${this.wst}/feed/sensors_monitor`)
      this.wsSensorsBandwidth = new WebSocket(`${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${window.location.host}/ws/${this.wst}/feed/sensor_bandwidth_monitor`)
      this.pullSensorMonitoringLatest(this.sensorIDs)
    },
    loadSensorMonitoring () {
      this.loading = true
      return Promise.all([
        this.$http.get(`/api/v1/sensor-monitoring?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}`)
          .then(body => body?.data?.data),
        this.$http.get(`/api/v1/sensor-monitoring/bandwidth?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}`)
          .then(body => body?.data?.data)
      ]).then(outputs => {
        if (!outputs || !outputs[0]) {
          return
        }

        const sensors = {}
        Object.keys(outputs[0]).forEach(sensorID => {
          const sensor = outputs[0][sensorID]
          const sensorSeries = Object.values(sensor.series)
          const sensorBandwidth = outputs[1][sensorID]
          const sensorInterfaces = sensorBandwidth.series

          const output = {
            id: sensorID,
            name: sensor.name,
            resourceLatest: {},
            bandwidth: {}
          }

          if (!sensorInterfaces || !Object.keys(sensorInterfaces).length) {
            output.resourceSeries = statsSeriesTimeseries(sensorSeries.map(item => {
              return {
                time: new Date(item.created_at),
                data: {
                  cpu: item.cpu,
                  disk: item.disk,
                  ram: item.ram
                }
              }
            }))

            sensors[sensorID] = output
            return
          }

          const interfaces = Object.keys(sensorInterfaces)
          const bandwidthAxes = interfaces.reduce((acc, val) => {
            acc[val] = 'y2'
            return acc
          }, {})

          output.resourceSeries = statsSeriesTimeseries(sensorSeries.map(item => {
            const bandwidth = interfaces.reduce((acc, val) => {
              acc[val] = sensorInterfaces[val][item.created_at]?.received_bytes_avg || 0
              return acc
            }, {})

            return {
              time: new Date(item.created_at),
              data: {
                cpu: item.cpu,
                disk: item.disk,
                ram: item.ram,
                ...bandwidth
              }
            }
          }), null, {
            axes: bandwidthAxes
          })
          sensors[sensorID] = output
        })

        this.sensors = sensors
      }).finally(() => {
        this.loading = false
      })
    },
    loadSensorNetflows () {
      this.loading = true
      return Promise.all(this.sensorIDs.map(sensorID => {
        return this.$http.get(`/api/v1/netflow/stats/${sensorID}?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&limit=10`)
          .then(body => ({
            id: sensorID,
            data: body && body.data
          }))
      }))
        .then(data => {
          this.statsNetflows = data.reduce((acc, v) => {
            acc[v.id] = statsSeriesTimeseries(v.data.data, null, 'Connections')
            return acc
          }, {})
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadSensorNetflowsTop () {
      this.loading = true
      return Promise.all(this.sensorIDs.map(sensorID => {
        return this.$http.get(`/api/v1/netflow/top/${sensorID}?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&limit=10`)
          .then(body => ({
            id: sensorID,
            data: body && body.data
          }))
      }))
        .then(data => {
          this.statsNetflowsTop = data.reduce((acc, v) => {
            acc[v.id] = statsSeriesPie(v.data.data, null, 'Connections')
            return acc
          }, {})
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
